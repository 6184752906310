import { Header, UserHeader } from 'components/layout/App/Header'
import { SiteFooter } from 'components/layout/App/SiteFooter'
import { withCleanLayout } from 'components/layout/Clean/CleanLayout'
import { pirsch } from 'lib/helpers/pirsch'
import { useCurrentUser } from 'lib/hooks/useCurrentUser'
import { NextSeo } from 'next-seo'
import { FreeportPage } from 'pages/_app'
import { useEffect, useRef } from 'react'
import { AsSeeonOnSection } from 'views/home/AsSeenOnSection'
import { GallerySection } from 'views/home/GallerySection'
import { HeroSection } from 'views/home/HeroSection'
import { HowFreeportWorks } from 'views/home/HowFreeportWorks'
import { JamesDeanFeatured } from 'views/home/JamesDeanFeatured'
import { MarilynFeatured } from 'views/home/MarilynFeatured'
import { MetricsSection } from 'views/home/MetricsSection'

const LandingPage: FreeportPage = () => {
  const warholCollection = useRef<HTMLDivElement>(null)
  const { user } = useCurrentUser()

  const handleScrollToCollection = () => {
    warholCollection.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    pirsch('landing_page_index')
  }, [])

  return (
    <>
      <NextSeo />

      <main className="flex-grow">
        {user ? <UserHeader /> : <Header />}

        <HeroSection handleScrollTo={handleScrollToCollection} />

        <div className="bg-white relative z-10">
          <AsSeeonOnSection />
          <HowFreeportWorks />
          <MetricsSection />
          <MarilynFeatured />
          <GallerySection forwardedRef={warholCollection} />
          <JamesDeanFeatured />
        </div>
      </main>
      <SiteFooter />
    </>
  )
}

LandingPage.layout = withCleanLayout

export default LandingPage
